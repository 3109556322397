<template>
	<div class="appWhite rounded-lg pa-4">

		<!--Title-->
		<app-text color="primary" size="normal-bold">{{ cardData.frcTitle }}</app-text>

		<!--Site-->
		<div class="d-flex align-end">
			<app-icon class="mr-2" color="primary" icon="sites" size="16"/>
			<app-text size="small">{{ siteData?.siteName }}</app-text>
		</div>

		<!--Type | Date-->
		<div class="d-flex align-center mt-2">
			<app-text class="mr-4" size="small">
				{{ cardData.frcType }}
				-
				{{ MIX_formatDate(cardData.createdDateTime, 'short') }}
			</app-text>
		</div>

	</div>
</template>

<script>

export default {

	name: "FireRollCallMobileCard",

	props: ['cardData', 'siteData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
