<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--User has NOT passed induction-->
		<div v-if="!form.entityId">

			<!--Information-->
			<app-text class="mt-4">
				Welcome to your induction, you must complete this before you can start work.
				<br>
				<br>
				After watching the video, please tap Get Started, and answer the five questions.
				<br>
				You will have five attempts to get all the questions correct.
			</app-text>

			<!--Video-->
			<div class="disable-dbl-tap-zoom">
				<video v-if="!areQuestionsVisible" ref="video"
					   @ended="onVideoEnded"
					   @timeupdate="onTimeUpdate"
					   class="rounded-lg mt-4 disable-dbl-tap-zoom" width="100%" controls>
					<source
						src="https://firebasestorage.googleapis.com/v0/b/swapp-edenstone-sta.appspot.com/o/final_edenstone%20(720p).mp4?alt=media&token=2619dd4f-f359-40ca-acd9-a9fb2f6952f5"
						type="video/mp4">
					Your browser does not support this video, please speak to a manager for further help.
				</video>
			</div>

			<!--Getting Started button-->
			<div v-if="isVideoWatched && !areQuestionsVisible" class="d-flex mt-4">
				<app-btn @click.native="handleGettingStartedButton" label="Get Started"/>
			</div>

			<!--Attempts-->
			<app-text v-if="areQuestionsVisible" class="text-right mt-4">
				You have <b>{{ getRemainingAttempts() }}</b> attempts remaining
			</app-text>

			<v-divider class="greyD mt-4"/>

			<!--Questions-->
			<div v-if="areQuestionsVisible">

				<div v-for="(item, index) in randomQuestions" class="mt-4">

					<app-text class="mt-4" color="primary" size="normal-bold">{{ `Question ${index + 1}` }}</app-text>

					<app-text class="mt-4">{{ item.question }}</app-text>

					<app-form-field form-type="select"
									class="mt-4"
									:clearable="true"
									:items="item.multipleChoiceAnswers"
									label="Answer"
									v-model="item.userAnswer"/>

					<v-divider class="greyD mt-4"/>

				</div>

				<!--Submit button-->
				<div class="d-flex flex-column align-end mt-4">

					<app-text v-if="isErrorWithQuestions" color="red" size="small">
						You must answer all questions before submitting your answers
					</app-text>

					<app-btn class="mt-4" color="green" label="Submit" @click.native="handleSubmitButton"/>

				</div>

			</div>

		</div>

		<!--User has passed induction-->
		<div v-if="form.entityId"
			 class="d-flex flex-column align-center mt-8">

			<app-text class="text-center">Present this to show you have completed your Induction.</app-text>

			<div class="d-flex flex-column align-center">

				<app-text class="mt-8" size="medium-bold">Induction Complete</app-text>
				<app-icon class="pulse" color="green" icon="success" size="256"/>
				<app-text size="small">
					<span>{{ MIX_formatDate(form.createdDateTime, 'long') }}</span>
					<span class="mx-2">@</span>
					<span>{{ MIX_formatDateTimeToTime(form.createdDateTime) }}</span>
				</app-text>

			</div>

		</div>

		<!--Dialogs ---------------- -->

		<!--Failed Attempt Dialog-->
		<app-dialog :is-visible="isFailedAttemptDialogVisible">
			<div class="appGrey rounded-lg pa-4">

				<app-text class="text-center">
					You have not answered all the questions correctly.
					<br>
					<br>
					Please try again.
					<br>
					<br>
					You have {{ getRemainingAttempts() }} attempts remaining.
				</app-text>

				<v-divider class="greyD mt-4"/>

				<app-btn @click.native="toggleFailedAttemptDialogVisibility"
						 :block="true"
						 class="mt-4"
						 label="Try Again"/>

			</div>
		</app-dialog>

		<!--Failed All Attempts Dialog-->
		<app-dialog :is-visible="isFailedAllAttemptsDialogVisible">
			<div class="appGrey rounded-lg pa-4">

				<app-text class="text-center">
					You have not answered all the questions correctly in the 5 allowed attempts.
					<br>
					<br>
					You will be given a different set of questions to try again.
					<br>
					<br>
					You have {{ getRemainingAttempts() }} attempts remaining.
				</app-text>

				<v-divider class="greyD mt-4"/>

				<app-btn @click.native="toggleFailedAllAttemptsDialogVisibility"
						 :block="true"
						 class="mt-4"
						 label="Try Again"/>

			</div>
		</app-dialog>

		<!--Report To Site Dialog-->
		<app-dialog :is-visible="isReportToSiteDialogVisible">
			<div class="appGrey rounded-lg pa-4">

				<!--Icon | Title-->
				<div class="d-flex align-center">
					<app-icon class="mr-4" color="green" icon="success" size="32"/>
					<app-text class="text-center" size="medium-bold">Induction Complete</app-text>
				</div>

				<v-divider class="greyD mt-4"/>

				<!--Information-->
				<app-text class="text-center mt-4">
					You have successfully completed your induction.
					<br>
					<br>
					Please report to the Site Office for your Orientation.
				</app-text>

				<v-divider class="greyD mt-4"/>

				<!--Close Button-->
				<app-btn @click.native="toggleReportToSiteDialogVisibility"
						 :block="true"
						 class="mt-4"
						 label="Close"/>

			</div>
		</app-dialog>

	</div>

</template>

<script>

export default {

	name: "InductionQuestions",

	data: () => ({
		areQuestionsVisible: false,
		attempts: 5,
		currentUserData: {},
		form: {
			entityId: '',
			inductionUserId: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
		},
		hasUserPassedInduction: false,
		isErrorWithQuestions: false,
		isFailedAttemptDialogVisible: false,
		isFailedAllAttemptsDialogVisible: false,
		isLoading: false,
		isReportToSiteDialogVisible: false,
		randomQuestions: [],

		isVideoWatched: false,
		videoWatchedThreshold: 1,
		lastCurrentTime: 0,

		// Data
		inductionQuestionsData: []
	}),

	methods: {

		openVideo() {
			window.open('https://vimeo.com/889097832/036c94cd44?share=copy', '_blank')
		},

		/**
		 * Create Item
		 *
		 * Create a new item in the database.
		 */
		async createItem() {
			const t = this

			t.form.inductionUserId = t.currentUserData.entityId

			const RESPONSE = await t.MIX_redis_create('induction', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating User Induction data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong when trying to create Inductions data, Please try again.'
				return
			}

			await t.loadUserInductionData()
		},

		/**
		 * Get Remaining Attempts
		 *
		 * Get the remaining attempts the user has.
		 */
		getRemainingAttempts() {
			const t = this
			return t.attempts
		},

		/**
		 * Handle All Questions Correct
		 *
		 * Handle the user answering all the questions correctly.
		 * Create the induction item and reset the user answers.
		 * Toggle the report to site dialog visibility.
		 */
		async handleAllQuestionsCorrect() {
			const t = this

			await t.createItem()

			t.toggleReportToSiteDialogVisibility()

			t.areQuestionsVisible = false
			t.attempts = 5
			t.resetUserAnswers()
		},

		/**
		 * Handle Failed All Attempts
		 *
		 * Handle the user failing all the attempts.
		 * Reset the user answers, set the random questions and toggle the failed all attempts dialog visibility.
		 */
		handleFailedAllAttempts() {
			const t = this

			t.areQuestionsVisible = false
			t.attempts = 5
			t.resetUserAnswers()
			t.setRandomQuestions()
			t.toggleFailedAllAttemptsDialogVisibility()
		},

		/**
		 * Handle Getting Started Button
		 *
		 * Handle the user clicking the get started button.
		 * Set the questions to be visible and pick five random questions.
		 */
		handleGettingStartedButton() {
			const t = this

			// Set the questions to be visible
			t.areQuestionsVisible = true

			// Pick five random questions
			t.setRandomQuestions()
		},

		/**
		 * Handle Next Attempt
		 *
		 * Handle the user needing to try again.
		 * Decrement the number of attempts, reset the user answers, and toggle the failed attempt dialog visibility.
		 */
		handleNextAttempt() {
			const t = this

			// Decrement the attempts
			t.attempts--

			// Set the random questions if the user has exhausted all their attempts
			if (t.attempts === 0) {
				t.handleFailedAllAttempts()
				return
			}

			// Reset the user answers
			t.resetUserAnswers()

			// Toggle the failed attempt dialog
			t.toggleFailedAttemptDialogVisibility()
		},

		/**
		 * Handle Submit Button
		 *
		 * Handle the user clicking the submit button.
		 * Check if the user has answered all the questions and if they have answered them correctly.
		 *  - If they have answered them correctly, handle all questions correct.
		 *  - If they have not answered them correctly, handle next attempt.
		 */
		handleSubmitButton() {
			const t = this

			// Reset the error flag
			t.isErrorWithQuestions = false

			// Check if the user has answered all the questions
			const HAS_USER_ANSWERED_ALL_QUESTIONS = t.randomQuestions.every(item => item.userAnswer !== null)

			if (HAS_USER_ANSWERED_ALL_QUESTIONS) {

				// Check if the user has answered all the questions correctly
				const HAS_USER_ANSWERED_ALL_QUESTIONS_CORRECTLY = t.randomQuestions.every(item => item.userAnswer === item.answer)

				if (HAS_USER_ANSWERED_ALL_QUESTIONS_CORRECTLY) t.handleAllQuestionsCorrect()
				else t.handleNextAttempt()

			} else t.isErrorWithQuestions = true

		},

		/**
		 * Load Data
		 *
		 * Load the data for the page.
		 */
		async loadData() {
			const t = this

			// Set the loading flag
			t.isLoading = true

			await Promise.all([
				t.loadUserInductionData(),
				t.loadInductionQuestionsData(),
			])

			// Set the loading flag
			t.isLoading = false
		},

		/**
		 * Load User Induction Data
		 *
		 * Load the user induction data.
		 */
		async loadUserInductionData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getInductionsWhere([
				{
					whereKey: 'inductionUserId',
					whereValue: t.currentUserData.entityId,
				}
			])

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting User Inductions data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong when trying to get Inductions data, Please try again.'
				return
			}

			// Set the user induction data
			if (RESPONSE.data.length) t.form = RESPONSE.data[0]
		},

		/**
		 * Load Induction Questions Data
		 *
		 * Load the induction questions data.
		 */
		async loadInductionQuestionsData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('inductionQuestion')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error loading Induction Questions: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem loading Induction Questions, please try again.'
				return
			}

			t.inductionQuestionsData = RESPONSE.data
		},

		/**
		 * On Time Update
		 *
		 * Handle the video time update.
		 * Check if the user has watched the video.
		 */
		onTimeUpdate() {
			const t = this

			const video = t.$refs.video
			const watchedPercentage = video.currentTime / video.duration
			if (watchedPercentage >= t.videoWatchedThreshold) t.isVideoWatched = true
		},

		/**
		 * On Video Ended
		 *
		 * Handle the video ending.
		 * Set the video as watched.
		 */
		onVideoEnded() {
			const t = this
			t.isVideoWatched = true
		},

		/**
		 * Reset User Answers
		 *
		 * Reset the user answers for the questions.
		 */
		resetUserAnswers() {
			const t = this
			t.randomQuestions.forEach(item => item.userAnswer = null)
		},

		/**
		 * Set Random Questions
		 *
		 * Set the random questions for the user to answer.
		 * The answers for each question are shuffled so the correct answer is not always in the same position.
		 */
		setRandomQuestions() {
			const t = this

			// Shuffle questions
			const SHUFFLED_QUESTIONS = t.inductionQuestionsData.sort(() => Math.random() - Math.random()).slice(0, 5)

			// Set random questions to the formatted questions array
			t.randomQuestions = SHUFFLED_QUESTIONS.map(question => {

				const answers = [
					question.inductionCorrectAnswer,
					question.inductionFalseAnswer1,
					question.inductionFalseAnswer2,
					question.inductionFalseAnswer3,
				]

				// Shuffle answers
				const shuffledAnswers = t.shuffleArray(answers)

				// Construct formatted question object
				return {
					answer: question.inductionCorrectAnswer,
					multipleChoiceAnswers: shuffledAnswers,
					question: question.inductionQuestion,
					userAnswer: null,
				}

			})
		},

		/**
		 * Shuffle Array
		 *
		 * Shuffle the array.
		 *
		 * @param array {array} the array to shuffle
		 * @returns {array} the shuffled array
		 */
		shuffleArray(array) {
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));

				[array[i], array[j]] = [array[j], array[i]]
			}

			return array
		},

		/**
		 * Toggle Failed Attempt Dialog Visibility
		 *
		 * Toggle the failed attempt dialog visibility.
		 */
		toggleFailedAttemptDialogVisibility() {
			const t = this
			t.isFailedAttemptDialogVisible = !t.isFailedAttemptDialogVisible
		},

		/**
		 * Toggle Failed All Attempts Dialog Visibility
		 *
		 * Toggle the failed all attempts dialog visibility.
		 */
		toggleFailedAllAttemptsDialogVisibility() {
			const t = this
			t.isFailedAllAttemptsDialogVisible = !t.isFailedAllAttemptsDialogVisible
		},

		/**
		 * Toggle Report To Site Dialog Visibility
		 *
		 * Toggle the report to site dialog visibility.
		 */
		toggleReportToSiteDialogVisibility() {
			const t = this
			t.isReportToSiteDialogVisible = !t.isReportToSiteDialogVisible
		},

	},

	async mounted() {
		const t = this
		const VIDEO = t.$refs.video

		t.currentUserData = t.MIX_getCurrentUser()

		await t.loadData()

		// Disable seeking
		VIDEO.addEventListener('seeking', () => {
			if (!t.isVideoWatched) VIDEO.currentTime = t.lastCurrentTime
		})

		// Track last valid current time
		VIDEO.addEventListener('timeupdate', () => {
			if (!VIDEO.seeking) t.lastCurrentTime = VIDEO.currentTime
		})

	},

}
</script>

<style scoped>
.disable-dbl-tap-zoom {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

/* Hide the seek bar in Chrome, Safari, and Edge */
video::-webkit-media-controls-timeline {
	display: none !important;
}

/* Hide the seek bar in Firefox */
video::-moz-range-track {
	display: none !important;
}

/* Hide the seek bar in IE */
video::-ms-track {
	display: none !important;
}

</style>
