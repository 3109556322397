<template>
	<div>

		<!--If Induction HAS been completed -------------------------------- -->
		<div v-if="inductionData?.entityId" class="d-flex align-center">
			<app-icon class="mr-4" color="green" icon="inductions" size="48"/>

			<app-text>
				Induction completed on
				<b>{{ MIX_formatDate(inductionData.createdDateTime, 'long') }}</b>
				@
				<b>{{ MIX_formatDateTimeToTime(inductionData.createdDateTime) }}</b>.
			</app-text>
		</div>

		<!--If Induction has NOT been completed -------------------------------- -->
		<div v-if="!inductionData?.entityId" class="d-flex align-center">
			<app-icon class="mr-4" color="red" icon="inductions" size="48"/>

			<app-text>
				Induction has not been completed.
			</app-text>
		</div>

	</div>
</template>

<script>

export default {

	name: "UserInductions",

	props: ['inductionData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
