<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Action Bar-->
		<div class="d-flex align-center mt-4">

			<!--Search-->
			<app-form-field form-type="textInput"
							append-icon="icons8-search"
							class="mr-4"
							:clearable="true"
							label="Accident Title"
							style="width: 100%"
							v-model.trim="searchByAccidentTitle"/>

			<!--Add Button-->
			<app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add"/>

			<!--Filter Button - with a numbered badge-->
			<div style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4" color="appWhite" icon="filter" icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption"/>

		</div>

		<!--Clear Filters-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Table-->
		<v-data-table v-if="$vuetify.breakpoint.width >= 600"
					  class="appWhite rounded-lg mt-4"
					  :headers="computedHeaders"
					  :items="computedTableData">

			<!--ID-->
			<template v-slot:item.entityId="{item}">
				<app-text size="small">{{ item.reportData.entityId }}</app-text>
			</template>

			<!--Title-->
			<template v-slot:item.accidentReportTitle="{item}">
				<app-text size="small">
					{{ item.reportData.accidentReportTitle }}
				</app-text>
			</template>

			<!--Event-->
			<template v-slot:item.eventName="{item}">
				<app-text v-if="item.eventData?.eventName" size="small">{{ item.eventData.eventName }}</app-text>
				<app-text v-else color="greyD" size="small">N/A</app-text>
			</template>

			<!--Date-->
			<template v-slot:item.accidentReportDate="{item}">
				<app-text size="small">{{ MIX_formatDate(item.reportData.accidentReportDate, 'short') }}</app-text>
				<app-text size="small">@ {{ item.reportData.accidentReportTime }}</app-text>
			</template>

			<!--Type-->
			<template v-slot:item.accidentReportType="{item}">
				<app-text size="small">{{ item.reportData.accidentReportType }}</app-text>
			</template>

			<!--Injured Party Name-->
			<template v-slot:item.accidentReportInjuredPartyName="{item}">
				<app-text size="small">{{ item.reportData.accidentReportInjuredPartyName }}</app-text>
			</template>

			<!--Action Button-->
			<template v-slot:item.action="{item}">
				<app-icon @click.native="openRightPanel(item)"
						  class="cursorPointer" color="primary" icon="arrowForward"/>
			</template>

		</v-data-table>

		<!--Mobile Cards-->
		<div v-if="$vuetify.breakpoint.width < 600">

			<!--No Reports Message-->
			<app-text v-if="!computedTableData.length" class="text-center mt-4" color="grey9">
				You have no Reports to view
			</app-text>

			<accident-mobile-card v-for="item in computedTableData" :key="item.id"
								  @click.native="openRightPanel(item)"
								  class="mt-4"
								  :fullReportData="item"/>

		</div>

		<!--Filters Panel ------------------------------------------------------------------------------------------ -->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Event-->
				<page-break-title title="Event"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Event"
									v-model.trim="filterByEvent"/>
				</div>

				<!--Types-->
				<page-break-title class="mt-8" title="Types"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:deletable-chips="true"
									:items="accidentTypeOptions"
									label="Types"
									:multiple="true"
									:small-chips="true"
									v-model="filterByTypes"/>
				</div>

				<!--Injured Party-->
				<page-break-title class="mt-8" title="Injured Party"/>
				<div class="mt-4">
					<app-form-field form-type="textInput"
									label="Injured Party"
									v-model.trim="filterByInjuredParty"/>
				</div>

			</div>
		</filter-panel>

		<!--Right Side ----------------------------------------------------------------------------------- Right Side-->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">

			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
				 v-model="isRightPanelVisible">

				<!--Header-->
				<div class="d-flex align-center primary pa-4">
					<app-text color="appWhite" size="normal">{{ selectedItem?.observationTitle || 'New' }}</app-text>
					<v-spacer/>
					<app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32"/>
				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs">
					<v-tab href="#accident">
						<app-text size="small">Accident</app-text>
					</v-tab>
				</v-tabs>

				<!--Tabs Content-->
				<v-tabs-items v-model="tabs">

					<!--Accident-->
					<v-tab-item value="accident">
						<accident-form @emitReloadPage="closeRightPanel"
									   class="pa-4"
									   :fullReportData="selectedItem"
									   :teamsData="teamsData"
									   :eventsData="eventsData"
									   :isReadOnly="isReadOnly"
									   :sitesData="sitesData"/>
					</v-tab-item>

				</v-tabs-items>

			</div>

		</transition>

	</div>

</template>

<script>
import AccidentMobileCard from "@/views/reporting/accidents/accidentMobileCard/AccidentMobileCard.vue";
import AccidentForm from "@/views/reporting/accidents/accidentForm/AccidentForm.vue";

export default {

	name: "AccidentsPage",

	components: {
		AccidentForm,
		AccidentMobileCard,
	},

	data: () => ({
		activeFilters: [],
		filterByEvent: '',
		filterByInjuredParty: '',
		filterByTypes: [],
		isFiltersPanelVisible: false,
		isRightPanelVisible: false,
		isLoading: true,
		isReadOnly: false,
		moreActionsMenuOption: [
			// {name: 'Columns', icon: 'columns'},
			{name: 'Export', icon: 'export'}
		],
		searchByAccidentTitle: '',
		searchEvent: '',
		searchName: '',
		searchType: '',
		selectedItem: {},
		tableHeaders: [
			{text: 'ID', value: 'entityId', align: 'start', sortable: false, hidden: true},
			{text: 'Title', value: 'accidentReportTitle', align: 'start', sortable: false,},
			{text: 'Event', value: 'eventName', align: 'start', sortable: false},
			{text: 'Date', value: 'accidentReportDate', align: 'start', sortable: false, width: '128px'},
			{text: 'Type', value: 'accidentReportType', align: 'start', sortable: false},
			{text: 'Injured Party', value: 'accidentReportInjuredPartyName', align: 'start', sortable: false},
			{text: '', value: 'action', align: 'center', sortable: false, width: '48px'},
		],
		tabs: '',

		accidentReportsData: [],
		eventsData: [],
		sitesData: [],
		teamsData: [],
	}),

	computed: {

		computedHeaders() {
			const t = this
			let headers = t.tableHeaders

			headers = headers.filter(h => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this
			return [
				t.filterByEvent ? 1 : 0,
				t.filterByInjuredParty ? 1 : 0,
				t.filterByTypes.length,
			].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the form data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			const CURRENT_USER_DATA = t.MIX_getCurrentUser()
			let reportsData = t.accidentReportsData

			// Staff Admin can see all, everyone else can only see their own
			if (CURRENT_USER_DATA.userLevel !== 'Staff-Admin') reportsData = reportsData.filter(rd => rd.reportData.createdUserId === CURRENT_USER_DATA.entityId)

			// Search by Accident Title
			if (t.searchByAccidentTitle) {
				const SEARCH_CRITERIA = t.searchByAccidentTitle.toUpperCase()
				reportsData = reportsData.filter(rd => {
					const ACCIDENT_TITLE = rd.reportData.accidentReportTitle?.toUpperCase()
					return ACCIDENT_TITLE.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Event
			if (t.filterByEvent) {
				const SEARCH_CRITERIA = t.filterByEvent.toUpperCase()
				reportsData = reportsData.filter(rd => {
					const EVENT_NAME = rd.eventData?.eventName?.toUpperCase()
					return EVENT_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Injured Party
			if (t.filterByInjuredParty) {
				const SEARCH_CRITERIA = t.filterByInjuredParty.toUpperCase()
				reportsData = reportsData.filter(rd => {
					const INJURED_PARTY_NAME = rd.reportData.accidentReportInjuredPartyName?.toUpperCase()
					return INJURED_PARTY_NAME.includes(SEARCH_CRITERIA)
				})
			}

			// Filter by Types
			if (t.filterByTypes.length) {
				reportsData = reportsData.filter(rd => {
					const ACCIDENT_TYPE = rd.reportData.accidentReportType
					return t.filterByTypes.includes(ACCIDENT_TYPE)
				})
			}

			// Sort by Date
			reportsData = reportsData.sort((a, b) => a.reportData.accidentReportDate < b.reportData.accidentReportDate ? 1 : -1)

			return reportsData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this

			t.filterByEvent = ''
			t.filterByInjuredParty = ''
			t.filterByTypes = []
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.tabs = ''
			t.selectedItem = {}
			t.isRightPanelVisible = false
			t.loadData()
		},

		/**
		 * Delete Item
		 *
		 * Delete the selected item (only marks as deleted).
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteItem(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('accidentReport', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting Accident Report: ', RESPONSE.error)
				return
			}

			await t.loadData()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'New') t.openRightPanel()
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadAccidentReportsData(),
				t.loadEventsData(),
				t.loadSitesData()
			])

			t.isLoading = false
		},

		/**
		 * Load Accident Reports Data
		 *
		 * Load Accident Reports data from the database.
		 * This loads the fullReportsData:
		 *  - Accident Reports
		 *  - Required Event Data
		 *
		 * @returns {Promise<void>}
		 */
		async loadAccidentReportsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('accidentReport')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Accident Reports: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.accidentReportsData = RESPONSE.data
		},

		/**
		 * Load Events Data
		 *
		 * Load Events data from the database.
		 * Load all the Live Events and filter out anything that starts after today.
		 * This is so a future Event cannot be selected, but incomplete recent Event reports can.
		 *
		 * @returns {Promise<void>}
		 */
		async loadEventsData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getEventsWhere({whereKey: 'eventStatus', whereValue: 'Live'})

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Events: ', RESPONSE.error)
				return
			}

			// Only use Events that start before tomorrow (23:59)
			const newDate = new Date()
			const endOfToday = newDate.setUTCHours(23, 59, 59, 999)
			t.eventsData = RESPONSE.data?.filter(event => event.eventDate < new Date(endOfToday).getTime())
		},

		/**
		 * Load Sites Data
		 *
		 * Load Sites data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadSitesData() {
			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('site')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Sites: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.sitesData = RESPONSE.data
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item the selected key
		 */
		openRightPanel(item) {
			const t = this

			t.isReadOnly = !!item?.reportData?.entityId

			t.selectedItem = item

			t.isRightPanelVisible = true
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async created() {
		const t = this

		await t.loadData()
	},

}
</script>

<style scoped>
</style>
