<template>
	<div>

		{{ computedInit }}

		<!--Document Name-->
		<app-form-field form-type="textInput"
						:disabled="isReadOnly"
						:error="errors.documentName"
						:error-message="errors.documentNameErrorMessage"
						label="Document Name"
						v-model="form.documentName"/>

		<!--Is Acceptance Required Checkbox-->
		<div class="d-flex align-center mt-4">
			<div @click="handleIsAcceptanceRequired" class="appWhite d-flex align-center justify-center rounded-lg mr-4"
				 style="aspect-ratio:1/1; border: 1px solid lightgrey; height: 56px; width: 56px">
				<app-icon v-if="form.documentIsAcceptanceRequired" color="green" :disabled="isReadOnly" icon="check"
						  size="32"/>
			</div>
			<div>
				<app-text>Is Acceptance Required?</app-text>
				<app-text color="grey9" size="small">
					All Users will be required to acknowledge they've read and accepted this document
				</app-text>
			</div>
		</div>

		<!--Document Upload-->
		<div class="mt-4">

			<!--Image Upload button-->
			<image-upload v-if="!isReadOnly"
						  @emitImageUpload="emittedImageUpload"
						  :disabled="!!form?.entityId"
						  file-type="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
						  label="Upload Document"
						  folder="documentation"
						  :id-for-image="form.entityId"
						  image-for="documentation"
						  style="width: fit-content"/>

			<div v-if="form?.entityId" class="d-flex align-center mt-2">
				<app-icon color="orange" icon="error" size="24"/>
				<app-text color="grey9" size="small">You cannot change files after creating a document.</app-text>
			</div>

			<!--File meta data-->
			<div v-if="uploadedDocument?.fileName">
				<app-text class="mt-2" color="grey9" size="small">{{ uploadedDocument.fileName }}</app-text>
				<app-text color="grey9" size="small">{{ uploadedDocument.fileSize }} KB</app-text>
			</div>

			<!--Missing File Error Message-->
			<app-text v-if="errors.documentUploadedDocument"
					  class="mt-2 ml-2" color="red" size="small">
				A document is required
			</app-text>

		</div>

		<form-section-title class="mt-8" title="Who's this for?"
							description="Select from the options below which users you want this documentation to apply to."/>

		<!--Checkboxes-->
		<div class="d-flex justify-space-around" style="width: 100%">
			<div v-for="item in computedUserTypesOptions" class="d-flex flex-column align-center">

				<!--Title-->
				<app-text>{{ item }}</app-text>

				<!--Checkbox-->
				<div @click="handleDocumentRecipientTypeSelection(item)"
					 class="appWhite d-flex align-center justify-center rounded-lg mt-2"
					 style="height: 56px; width: 56px">
					<app-icon v-if="form.documentRecipientType === item"
							  color="green" :disabled="isReadOnly" icon="check" size="40"/>
				</div>

			</div>
		</div>
		<!--Checkbox - Error Message-->
		<app-text v-if="errors.documentRecipientType" class="text-center mt-2" color="red" size="small"
				  style="width: 100%">
			{{ errors.documentRecipientTypeErrorMessage }}
		</app-text>

		<!--Users-->
		<v-col v-if="form.documentRecipientType === 'Users'"
			   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
			   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
			<app-form-field form-type="autoComplete"
							:clearable="true"
							:deletable-chips="true"
							:disabled="isReadOnly"
							:error="errors.documentRecipientTypeUsers"
							:error-message="errors.documentRecipientTypeUsersErrorMessage"
							:items="computedUsers"
							item-text="userName"
							label="Users"
							:multiple="true"
							:return-object="true"
							:small-chips="true"
							v-model="displayUserObjects"/>
		</v-col>

		<!--User Levels-->
		<v-col v-if="form.documentRecipientType === 'Levels'"
			   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
			   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
			<app-form-field form-type="autoComplete"
							:clearable="true"
							:deletable-chips="true"
							:disabled="isReadOnly"
							:error="errors.documentRecipientTypeLevels"
							:error-message="errors.documentRecipientTypeLevelsErrorMessage"
							:items="computedUserLevels"
							label="User Levels"
							:multiple="true"
							:small-chips="true"
							v-model="form.documentRecipientTypeLevels"/>
		</v-col>

		<!--User Types-->
		<v-col v-if="form.documentRecipientType === 'Types'" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
			   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
			<app-form-field form-type="autoComplete"
							:clearable="true"
							:deletable-chips="true"
							:disabled="isReadOnly"
							:error="errors.documentRecipientTypeTypes"
							:error-message="errors.documentRecipientTypeTypesErrorMessage"
							:items="computedUserTypes"
							label="User Types"
							:multiple="true"
							:small-chips="true"
							v-model="form.documentRecipientTypeTypes"/>
		</v-col>

		<!--Save Button-->
		<div v-if="!isReadOnly" class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSaveItem"
					 color="green"
					 icon="save"
					 label="Save"
					 style="width: fit-content"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "DocumentationForm",

	props: ['formData', 'isReadOnly', 'usersData'],

	data: () => ({
		displayUserObjects: [],
		errors: {
			documentName: false,
			documentNameErrorMessage: '',
			documentRecipientType: false,
			documentRecipientTypeErrorMessage: '',
			documentRecipientTypeUsers: false,
			documentRecipientTypeUsersErrorMessage: '',
			documentRecipientTypeLevels: false,
			documentRecipientTypeLevelsErrorMessage: '',
			documentRecipientTypeTypes: false,
			documentRecipientTypeTypesErrorMessage: '',
			documentUploadedDocument: false,
			documentUploadedDocumentErrorMessage: '',
		},
		form: {
			documentFileId: '',
			documentIsAcceptanceRequired: false,
			documentName: '',
			documentRecipientType: '',
			documentRecipientTypeUsers: [],
			documentRecipientTypeLevels: [],
			documentRecipientTypeTypes: [],
			documentType: '',
			documentUploadedByType: '',
			documentUploadedByOrganisationId: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},
		uploadedDocument: {},
	}),

	computed: {

		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.entityId) {
				t.form = FORM_DATA

				// Set the users to display in the UI
				t.displayUserObjects = t.usersData.filter(user => FORM_DATA.documentRecipientTypeUsers.includes(user.entityId))
			}
		},

		/**
		 * Computed Users
		 *
		 * Filter the usersData based on the current user's userType.
		 * Staff can see all users, while Organisations can only see users from their organisation.
		 *
		 * @returns {Array} - The filtered usersData
		 */
		computedUsers() {
			const t = this
			const CURRENT_USER = t.MIX_getCurrentUser()
			const USERS_DATA = t.$props.usersData
			let usersData = []

			// If the user is a staff member, show all users
			if (CURRENT_USER.userType === 'Staff') usersData = USERS_DATA

			// If the user is an organisation, show only users from their organisation
			if (CURRENT_USER.userType === 'Contractor') usersData = USERS_DATA.filter(user => user.userOrganisation === CURRENT_USER.userOrganisation)

			return usersData.sort((a, b) => a.userName > b.userName ? 1 : -1)
		},

		/**
		 * Computed User Levels
		 *
		 * Filter the usersData based on the current user's userType.
		 * Staff can see all user levels, while Organisations can only see user levels from their organisation.
		 *
		 * @returns {Array} - The filtered user levels
		 */
		computedUserLevels() {
			const t = this
			const CURRENT_USER = t.MIX_getCurrentUser()
			let userLevels = []

			// If the user is a staff member, show all user levels
			if (CURRENT_USER.userType === 'Staff') userLevels = t.$props.usersData.map(user => user.userLevel)

			// If the user is an organisation, show only user levels from their organisation
			if (CURRENT_USER.userType === 'Contractor') userLevels = t.$props.usersData.filter(user => user.userOrganisation === CURRENT_USER.userOrganisation).map(user => user.userLevel)

			return userLevels.sort((a, b) => a > b ? 1 : -1)
		},

		/**
		 * Computed User Types Options
		 *
		 * Filter the user types based on the current user's userType.
		 *
		 * @returns {Array} - The filtered user type options
		 */
		computedUserTypesOptions() {
			const t = this
			const CURRENT_USER = t.MIX_getCurrentUser()
			let typesOptions = []

			// If the user is a staff member, show all user types
			if (CURRENT_USER.userType === 'Staff') typesOptions = ['Users', 'Levels', 'Types', 'All']

			// If the user is an organisation, don't show the 'Types' option
			if (CURRENT_USER.userType === 'Contractor') typesOptions = ['Users', 'Levels', 'All']

			return typesOptions
		},

		/**
		 * Computed User Types
		 *
		 * Filter the usersData based on the current user's userType.
		 *
		 * @returns {Array} - The filtered user types
		 */
		computedUserTypes() {
			const t = this
			const USER_TYPES = t.$props.usersData.map(user => user.userType)
			return USER_TYPES.sort((a, b) => a > b ? 1 : -1)
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async createItem() {
			const t = this

			// Set the form's documentFileId to the uploaded document's entityId
			t.form.documentFileId = t.uploadedDocument.entityId
			t.form.documentType = t.uploadedDocument.fileType

			const RESPONSE = await t.MIX_redis_createDocumentation(t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving Documentation to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem saving your documentation, please try again.'
				return
			}

			// Update the uploaded document's fileDocLink
			await t.updateUploadedFile(RESPONSE.data.entityId)

			// Close the form
			t.$emit('emitReloadPage')
		},

		/**
		 * Emitted Image Upload
		 *
		 * When an image is uploaded, break apart the downloadURL and save what's required.
		 * Then, update the form with the image ID.
		 *
		 * @param payload - The image data and download URL
		 */
		async emittedImageUpload(payload) {
			const t = this

			// Set the temp profile picture to render in the UI
			t.tempProfilePicture = payload.downloadURL

			const {FOLDER_NAME, IMAGE_NAME, TOKEN} = t.MIX_extractImageDataFromUrl(payload.downloadURL)

			// Save the image data to the DB (Files collection), and add the returned image ID to the form
			t.uploadedDocument.documentFileId = await t.saveImageData(
				t.form.entityId,
				FOLDER_NAME,
				IMAGE_NAME,
				payload.image.size,
				TOKEN,
				payload.image.type
			)
		},

		/**
		 * Handle Document Recipient Selection
		 */
		handleDocumentRecipientTypeSelection(item) {
			const t = this

			// If the form is read only, don't allow changes
			if (t.$props.isReadOnly) return

			t.form.documentRecipientType = item
		},

		handleIsAcceptanceRequired() {
			const t = this

			// If the form is read only, don't allow changes
			if (t.$props.isReadOnly) return

			t.form.documentIsAcceptanceRequired = !t.form.documentIsAcceptanceRequired
		},

		async handleSaveItem() {
			const t = this
			const CURRENT_USER = t.MIX_getCurrentUser()

			// Only continue if the form has passed validation
			if (!t.validateForm()) return

			// Empty the List that are not selected
			if (t.form.documentRecipientType !== 'Users') t.form.documentRecipientTypeUsers = []
			if (t.form.documentRecipientType !== 'Levels') t.form.documentRecipientTypeLevels = []
			if (t.form.documentRecipientType !== 'Types') t.form.documentRecipientTypeTypes = []

			// If the current user is an organisation, and the documentRecipientType is 'All', only add the organisation's users
			if (CURRENT_USER.userType === 'Contractor' && t.form.documentRecipientType === 'All') {
				t.form.documentRecipientTypeUsers = t.usersData.filter(user => user.userOrganisation === CURRENT_USER.userOrganisation)
			}

			// If the documentRecipientType is Users, save their IDs
			if (t.form.documentRecipientType === 'Users') t.form.documentRecipientTypeUsers = t.displayUserObjects.map(user => user.entityId)

			// Set the created by user details - this is to limit the user's access to their own documents
			t.form.documentUploadedByType = CURRENT_USER.userType
			if (CURRENT_USER.userType === 'Contractor') t.form.documentUploadedByOrganisationId = CURRENT_USER.userOrganisation

			if (!t.form?.entityId) await t.createItem()
			else await t.updateItem()
		},

		/**
		 * Save Image Data
		 *
		 * Save the image data to the DB (Files collection).
		 *
		 * @param fileDocLink {string} - The file's document link
		 * @param fileFolder {string} - The storage folder
		 * @param fileName {string} - The file name
		 * @param fileSize {number} - The file size
		 * @param fileToken {string} - The file token
		 * @param fileType {string} - The file type
		 * @returns {Promise<*>} - The image ID
		 */
		async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
			const t = this

			const IMAGE_DATA = {
				fileDocLink,
				fileFolder,
				fileName,
				fileSize,
				fileToken,
				fileType,
				createdDateTime: 0,
				createdUserId: '',
				createdUserName: '',
				modifiedDateTime: 0,
				modifiedUserId: '',
				modifiedUserName: '',
				isDeleted: false,
				deletedDateTime: 0,
				deletedUserId: '',
				deletedUserName: '',
			}

			const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}

			t.uploadedDocument = RESPONSE.data

			return RESPONSE.data?.entityId
		},

		async updateItem() {
			const t = this

			// Set the form's documentFileId to the uploaded document's entityId
			if (!t.form.documentFileId) {
				t.form.documentFileId = t.uploadedDocument?.entityId
				t.form.documentType = t.uploadedDocument?.fileType
			}

			const RESPONSE = await t.MIX_redis_updateDocumentation(t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating Documentation to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem updating your documentation, please try again.'
				return
			}

			// Update the uploaded document's fileDocLink
			if (t.uploadedDocument?.entityId) await t.updateUploadedFile(RESPONSE.data.entityId)

			// Close the form
			t.$emit('emitReloadPage')
		},

		async updateUploadedFile(documentId) {
			const t = this

			t.uploadedDocument.fileDocLink = documentId

			const RESPONSE = await t.MIX_redis_update('file', t.uploadedDocument.entityId, t.uploadedDocument)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}
		},

		/**
		 * Validate Form
		 *
		 * Validate the required form fields and return a boolean if the form has passed, or not.
		 *
		 * @returns {boolean} - if the form has passed validation, or not
		 */
		validateForm() {
			const t = this

			t.clearErrors()

			// Document Name
			if (!t.form.documentName) {
				t.errors.documentName = true
				t.errors.documentNameErrorMessage = 'A Name is required'
			}

			// Uploaded Document
			if (!(t.form.documentFileId || t.uploadedDocument?.entityId)) {
				t.errors.documentUploadedDocument = true
				t.errors.documentUploadedDocumentErrorMessage = 'A document is required'
			}

			// Send To Type
			if (!t.form.documentRecipientType) {
				t.errors.documentRecipientType = true
				t.errors.documentRecipientTypeErrorMessage = 'A Recipient Type is required'
			}

			// Send to List Users
			if (t.form.documentRecipientType === 'Users' && !t.displayUserObjects.length) {
				t.errors.documentRecipientTypeUsers = true
				t.errors.documentRecipientTypeUsersErrorMessage = 'You must select at least one user'
			}

			// Send to List Levels
			if (t.form.documentRecipientType === 'Levels' && !t.form.documentRecipientTypeLevels.length) {
				t.errors.documentRecipientTypeLevels = true
				t.errors.documentRecipientTypeLevelsErrorMessage = 'You must select at least one user level'
			}

			// Send to List Types
			if (t.form.documentRecipientType === 'Types' && !t.form.documentRecipientTypeTypes.length) {
				t.errors.documentRecipientTypeTypes = true
				t.errors.documentRecipientTypeTypesErrorMessage = 'You must select at least one user type'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

}
</script>

<style scoped>

</style>
