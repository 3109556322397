<template>
	<div class="appWhite d-flex align-center rounded-lg pa-4" style="height: 108px">

		<!--Profile Picture-->
		<div class="d-flex align-center justify-center rounded-lg"
			 style="aspect-ratio: 1/1; border: 1px solid #f5f5f5; height: 76px; width: 76px">
			<app-icon color="greyD" icon="user" size="60"/>
		</div>

		<!--User Details-->
		<div class="d-flex flex-column justify-space-between mx-4" style="height: 100%">

			<!--Name-->
			<app-text size="normal-bold">{{ userData.userName }}</app-text>

			<!--Position-->
			<app-text color="grey9" size="small">{{ userData.userPosition }}</app-text>

			<!--SWAPP-->
			<app-text size="small">
				SWAPPed {{ userData.userSwappStatus }} @ {{ MIX_formatDateTimeToTime(userData.userLastSwappDateTime) }}
			</app-text>

		</div>

		<v-spacer/>

		<!--Checkbox-->
		<div @click="handleCheckboxSelection" class="d-flex align-center justify-center"
			 style="aspect-ratio: 1/1; border: 1px solid #f5f5f5; border-radius: 50%; height: 76px; width: 76px">
			<app-icon :color="userData.isChecked ? 'green' : 'greyD'" icon="success" size="56"/>
		</div>

	</div>
</template>

<script>

export default {

	name: "FireRollCallUserCard",

	props: ['userData'],

	data: () => ({}),

	methods: {

		handleCheckboxSelection() {
			const t = this

			t.$emit('handleCheckboxSelection', t.userData)
		}

	},

}
</script>

<style scoped>

</style>
