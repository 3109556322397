<template>
	<div>

		{{ computedInit }}

		<!--Document acceptance message-->
		<div>

			<!--No acceptance required-->
			<app-text v-if="!selectedItem.documentIsAcceptanceRequired">
				This document is for reference only, no acceptance is required.
			</app-text>

			<!--Acceptance required - Not accepted-->
			<app-text v-if="selectedItem.documentIsAcceptanceRequired && !form.documentActionHasAccepted">
				You are required to mark that you have read this document by checking the box below
			</app-text>

			<!--Acceptance required - Accepted-->
			<div v-else-if="selectedItem.documentIsAcceptanceRequired && form.documentActionHasAccepted"
				 class="d-flex align-center">
				<app-icon class="mr-4" color="green" icon="success" size="32"/>
				<app-text>
					You accepted this on {{ MIX_formatDate(form.documentActionAcceptedDateTime, 'short') }}
					@
					{{ MIX_formatDateTimeToTime(form.documentActionAcceptedDateTime) }}
				</app-text>
			</div>

		</div>

		<!--Document-->
		<v-img v-if="['image/jpeg', 'image/png'].includes(selectedItem.documentType)"
			   :src="getFile"
			   class="mt-4"
			   height="100%"
			   width="100%"/>
		<iframe v-else
				:src="getFile"
				class="d-flex mt-4"
				style="display: flex;
				justify-content: center;
				border: none;
				height: 100vh !important;
				width: 100% !important;"/>

		<!--Acceptance Checkbox-->
		<div v-if="!form.documentActionHasAccepted" class="d-flex align-center mt-4">
			<div @click="handleAcceptance"
				 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
				 style="aspect-ratio: 1/1; border: 1px solid lightgrey; height: 56px; width: 56px">
				<app-icon v-if="isAccepted" color="green" icon="check" size="32"/>
			</div>
			<app-text>
				I have read and accept the terms of this document
			</app-text>
		</div>

		<!--Save button-->
		<div v-if="!form.documentActionHasAccepted"
			 class="d-flex justify-end mt-4">
			<app-btn v-if="isAccepted"
					 @click.native="handleSave"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>
</template>

<script>

import {onBeforeUnmount} from "vue";

export default {

	name: "DocumentationViewer",

	props: ['fileUrl', 'selectedItem', 'userDocumentActionData'],

	data: () => ({
		form: {
			entityId: '',
			documentActionAcceptedDateTime: 0,
			documentActionFirstOpenedDateTime: 0,
			documentActionHasAccepted: false,
			documentActionUserId: '',
			documentDocumentId: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
			isDeleted: false,
		},
		isAccepted: false,
	}),

	computed: {

		/**
		 * Computed Headers
		 *
		 * Initialise the form data.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.userDocumentActionData

			if (FORM_DATA.entityId) t.form = FORM_DATA
		},

		/**
		 * Get File
		 */
		getFile() {
			const t = this
			let url

			// PDF
			if (t.$props.selectedItem.documentType === "application/pdf") url = t.$props.fileUrl

			// MPEG
			else if (t.$props.selectedItem.documentType === "video/mpeg") url = t.$props.fileUrl

			// Office
			else if (
				(t.$props.selectedItem.documentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
				(t.$props.selectedItem.documentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
				(t.$props.selectedItem.documentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {

				url = "https://view.officeapps.live.com/op/embed.aspx?src=" + t.$props.fileUrl
			}

			// URL
			else url = t.$props.fileUrl

			return url
		},

	},

	methods: {

		/**
		 * Handle Acceptance
		 *
		 * Toggle the acceptance status.
		 */
		handleAcceptance() {
			const t = this

			t.isAccepted = !t.isAccepted
		},

		/**
		 * Handle Save
		 *
		 * Set the acceptance status and call to save the form.
		 */
		async handleSave() {
			const t = this

			// Set the required data
			t.form.documentActionHasAccepted = t.isAccepted
			if (t.form.documentActionAcceptedDateTime === 0) t.form.documentActionAcceptedDateTime = new Date().getTime()

			await t.updateItem()
		},

		/**
		 * Save First Opened Date Time
		 *
		 * Set the first opened date time if it hasn't been set yet.
		 */
		async saveFirstOpenedDateTime() {
			const t = this

			// Set the first opened date time if it hasn't been set yet
			if (t.form.documentActionFirstOpenedDateTime === 0) {
				t.form.documentActionFirstOpenedDateTime = new Date().getTime()
				await t.updateItem()
			}
		},

		/**
		 * Update Item
		 *
		 * Update the item.
		 */
		async updateItem() {
			const t = this

			// Create the item
			const RESPONSE = await t.MIX_redis_update('documentAction', t.form.entityId, t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating DocumentAction', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem saving your acceptance. Please try again.'
				return
			}

			// Set the form
			t.form = RESPONSE.data
		},

	},

	async mounted() {
		const t = this
		const CURRENT_DOCUMENT_ACTION = t.$props.userDocumentActionData

		// Set the first opened date time if it hasn't been set yet
		if (CURRENT_DOCUMENT_ACTION?.entityId && !CURRENT_DOCUMENT_ACTION?.documentActionHasAccepted) await t.saveFirstOpenedDateTime()

	},

	beforeDestroy() {
		this.$emit('emitReloadPage')
	}

}
</script>

<style scoped>

</style>
