<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="accreditation"
					info="View and manage user qualifications and abilities."
					title="Qualifications"/>

		<!--Clear Filters Button-->
		<div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
			<app-btn @click.native="clearFilters" icon="close" label="Clear Filters"/>
		</div>

		<!--Tabs-->
		<v-tabs v-model="pageTabs" class="mt-4 rounded-lg" show-arrows>
			<v-tab href="#qualifications">
				<app-text size="small">Qualifications</app-text>
			</v-tab>
			<v-tab href="#pending">
				<app-text size="small">Pending</app-text>
			</v-tab>
			<v-tab href="#allUsers">
				<app-text size="small">All Users</app-text>
			</v-tab>
		</v-tabs>

		<!--Qualifications (Presets) Action Bar-->
		<div v-if="pageTabs === 'qualifications'" class="d-flex align-center mt-4">
			<!--Searches-->
			<div @focusin="onSearchFocus" @focusout="onSearchBlur" style="width: 100%">
				<app-form-field form-type="textInput"
								append-icon="icons8-search"
								class="mr-4"
								:clearable="true"
								label="Qualification Name"
								v-model.trim="searchByQualification"/>
			</div>

			<!--Add Button-->
			<app-btn v-if="!isSearchFocused"
					 @click.native="openRightPanel({}, 'qualificationPreset')"
					 class="mr-4"
					 icon="add"
					 :label="$vuetify.breakpoint.width < 600 ? '' : 'Add'"/>

			<!--Filter Button - with a numbered badge-->
			<div v-if="!isSearchFocused" style="position:relative;">
				<app-btn @click.native="toggleFiltersVisibility"
						 class="mr-4"
						 color="appWhite"
						 icon="filter"
						 icon-color="primary"/>
				<span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
			</div>

			<!--More Actions Menu-->
			<more-actions-menu v-if="!isSearchFocused"
							   @emitMenuSelection="handleEmittedMenuSelection"
							   :menuList="moreActionsMenuOption"/>
		</div>

		<!--Tabs Content-->
		<v-tabs-items v-model="pageTabs" class="appGrey">

			<!--Qualifications (Presets) Tab-->
			<v-tab-item value="qualifications">

				<!--Presets Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  class="appWhite rounded-lg mt-4"
							  :headers="computedHeaders"
							  :items="computedTableData">

					<!--Preset ID-->
					<template v-slot:item.entityId="{ item }">
						<app-text size="small">{{ item.entityId }}</app-text>
					</template>

					<!--Qualification Preset Name-->
					<template v-slot:item.qualificationName="{ item }">
						<div class="d-flex align-center">
							<app-text size="small">{{ item.qualificationName }}</app-text>
						</div>
					</template>

					<!--Qualification Preset Category-->
					<template v-slot:item.qualificationCategoryName="{ item }">
						<app-text size="small">{{ getCategoryById(item.qualificationCategoryId) }}</app-text>
					</template>

					<!--Action Button-->
					<template v-slot:item.action="{ item }">
						<app-icon @click.native="openRightPanel(item, 'qualificationPreset')"
								  class="cursorPointer"
								  color="primary"
								  icon="arrowForward"/>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<!--No data message-->
					<app-text v-if="!computedTableData.length"
							  class="text-center mt-4" color="grey9">You have no Qualifications to view
					</app-text>

					<qualification-preset-mobile-card v-for="item in computedTableData"
													  :key="item.entityId"
													  @click.native="openRightPanel(item, 'qualificationPreset')"
													  :cardData="item"
													  :qualification-categories-data="qualificationCategoriesData"
													  class="mt-4"/>
				</div>

			</v-tab-item>

			<!--Pending Tab-->
			<v-tab-item value="pending">
				<qualifications-admin-table @emitQualificationSelection="handleQualificationSelection"
											:qualification-categories-data="qualificationCategoriesData"
											:qualification-presets-data="qualificationPresetsData"
											table-tab="pending"
											:users-data="usersData"/>
			</v-tab-item>

			<!--All Users Qualifications Tab-->
			<v-tab-item value="allUsers">
				<qualifications-admin-table @emitQualificationSelection="handleQualificationSelection"
											:qualification-categories-data="qualificationCategoriesData"
											:qualification-presets-data="qualificationPresetsData"
											table-tab="allUsers"
											:users-data="usersData"/>
			</v-tab-item>

		</v-tabs-items>

		<!--(Qualification Presets) Filters Panel-->
		<filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
			<div class="pa-4">

				<!--Category Filter-->
				<page-break-title class="mt-8" title="Category"/>
				<div class="mt-4">
					<app-form-field form-type="autoComplete"
									:clearable="true"
									:items="qualificationCategoriesData"
									item-text="qualificationCategoryName"
									label="Category"
									:multiple="true"
									:return-object="true"
									v-model="filterByCategory"/>
				</div>
			</div>
		</filter-panel>

		<!--Right Side --------------------------------------------------------------------------------------------- -->
		<transition enter-active-class="animate__animated animate__fadeInRight animate__faster"
					leave-active-class="animate__animated animate__fadeOutRight animate__faster"
					mode="out-in">
			<div v-if="isRightPanelVisible"
				 class="appGrey formShadow"
				 style="position:absolute; top:0; bottom: 0; right: 0; overflow: auto"
				 :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'">

				<!--Qualification Preset Form-->
				<div v-if="formType === 'qualificationPreset'">

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<v-spacer/>
						<app-icon @click.native="closeRightPanel"
								  class="cursorPointer" color="white" icon="close" size="32"/>
					</div>

					<!--Action Bar-->
					<!--WORKING BUT TEMPORARILY REMOVED UNTIL WE DECIDE IF PRESETS SHOULD BE EDITABLE/DELETE-ABLE-->
					<div class="d-flex align-center pa-4">
						<v-spacer/>
						<edit-icon @click.native="editItem" :isActive="!isReadOnly"/>
						<delete-icon v-if="selectedItem?.entityId"
									 @emitDeleteItem="deletePreset(selectedItem)"
									 itemName="qualificationName"
									 :selectedItem="selectedItem"/>
					</div>

					<!--Tabs-->
					<v-tabs v-model="tabs">
						<v-tab href="#overview">
							<app-text size="small">Overview</app-text>
						</v-tab>
					</v-tabs>

					<!--Tabs Content-->
					<v-tabs-items v-model="tabs">

						<v-tab-item class="appGrey" value="overview">

							<!--Qualification Presets Form-->
							<qualification-presets-form @reloadData="emittedReloadData"
														class="pa-4"
														:form-data="selectedItem"
														:is-read-only="isReadOnly"
														:qualification-categories-data="qualificationCategoriesData"
														:qualification-presets-data="qualificationPresetsData"/>

						</v-tab-item>

					</v-tabs-items>

				</div>

				<!--Qualification Admin Form-->
				<div v-if="formType === 'qualificationAdmin'">

					<!--Header-->
					<div class="d-flex align-center primary pa-4">
						<v-spacer/>
						<app-icon @click.native="closeRightPanel"
								  class="cursorPointer" color="white" icon="close" size="32"/>
					</div>

					<!--Action Buttons-->
					<div class="d-flex align-center pa-4">
						<v-spacer/>
						<edit-icon @click.native="editItem"
								   :isActive="!isReadOnly"/>
						<delete-icon @emitDeleteItem="deleteQualification(selectedItem)"
									 :itemName="selectedItem.qualificationName ? 'qualificationName' : 'qualificationTemporaryName'"
									 :selectedItem="selectedItem"/>
					</div>

					<!--Tabs-->
					<v-tabs v-model="tabs">
						<v-tab href="#overview">
							<app-text size="small">Overview</app-text>
						</v-tab>
					</v-tabs>

					<!--Tabs Content-->
					<v-tabs-items v-model="tabs">

						<v-tab-item class="appGrey px-4" value="overview">

							<!--Qualification Admin Form-->
							<qualifications-admin-form @reloadData="emittedReloadData"
													   :form-data="selectedItem"
													   :is-read-only="isReadOnly"
													   :qualification-categories-data="qualificationCategoriesData"
													   :qualification-presets-data="qualificationPresetsData"
													   :users-data="usersData"/>

						</v-tab-item>

					</v-tabs-items>

				</div>

			</div>
		</transition>

	</div>

</template>

<script>

import QualificationsAdminForm from "@/views/qualifications/qualificationsAdminForm/QualificationsAdminForm";
import QualificationsAdminTable from "@/views/qualifications/qualificationsAdminTable/QualificationsAdminTable";
import QualificationPresetsForm from "@/views/qualifications/qualificationPresetsForm/QualificationPresetsForm";
import QualificationPresetMobileCard
	from "@/views/qualifications/qualificationPresetMobileCard/QualificationPresetMobileCard";
import UserForm from "@/views/users/userForm/UserForm.vue";
import UserQualificationsTable from "@/views/users/userQualificationsTable/UserQualificationsTable.vue";
import UserQualificationForm from "@/views/users/userQualificationForm/UserQualificationForm.vue";

export default {

	name: 'Qualifications',

	components: {
		UserQualificationForm,
		UserQualificationsTable,
		UserForm,
		QualificationsAdminForm,
		QualificationsAdminTable,
		QualificationPresetsForm,
		QualificationPresetMobileCard,
	},

	data: () => ({

		isFiltersPanelVisible: false,
		isLoading: false,
		isReadOnly: false,
		isRightPanelVisible: false,
		isSearchFocused: false,
		moreActionsMenuOption: [{name: 'Export', icon: 'export'}],
		searchByQualification: '',

		tableHeaders: [
			{text: 'ID', value: 'id', align: 'left', sortable: false, hidden: true},
			{
				text: 'Qualification Name',
				value: 'qualificationName',
				align: 'left',
				sortable: false,
			},
			{
				text: 'Qualification Category',
				value: 'qualificationCategoryName',
				align: 'left',
				sortable: false,
			},
			{
				text: '',
				value: 'action',
				align: 'right',
				sortable: false,
				width: '48px',
			},
		],

		// Data
		filterByCategory: [],
		formType: '',
		qualificationCategoriesData: [],
		qualificationPresetsData: [],
		selectedItem: {},
		tableTab: '',
		tabs: '',
		pageTabs: '',
		usersData: [],

	}),

	computed: {

		/**
		 * Computed Export CSV
		 *
		 * Return the data and headers for the CSV export
		 *
		 * @returns {{headers: {}, data: *[]}}
		 */
		computedExportCSV() {
			const t = this
			let data = []
			let headers = {}

			// Add the readable headers for the CSV columns
			headers = {
				qualificationName: 'Qualification',
				qualificationCategory: 'Category'
			}

			// Add the data
			t.computedTableData.forEach(item => {

				const DATA_OBJECT = {
					qualificationName: item?.qualificationName,
					qualificationCategory: t.getCategoryById(item?.qualificationCategoryId),
				}

				data.push(DATA_OBJECT)
			})

			return {headers, data}
		},

		/**
		 * Computed Headers
		 *
		 * Remove hidden headers and only show the actions column to those with access.
		 *
		 * @returns an array of header objects
		 */
		computedHeaders() {
			const t = this

			let headers = t.tableHeaders

			// Remove hidden headers
			headers = headers.filter((h) => !h.hidden)

			return headers
		},

		/**
		 * Computed Number of Active Filters
		 *
		 * Count the number of active filters to display in the filters button badge.
		 *
		 * @returns {*}
		 */
		computedNumberOfActiveFilters() {
			const t = this

			return [t.filterByCategory.length].reduce((acc, curr) => acc + curr, 0)
		},

		/**
		 * Computed Table Data
		 *
		 * Sort and return the table data.
		 *
		 * @returns {array[{}]} an array of objects
		 */
		computedTableData() {
			const t = this
			let tableData = t.qualificationPresetsData

			// Search by Qualification Name
			if (t.searchByQualification) {
				const SEARCH_CRITERIA = t.searchByQualification.toUpperCase()
				tableData = tableData.filter(item => item.qualificationName.toUpperCase().includes(SEARCH_CRITERIA))
			}

			// Filter by Category Name
			if (t.filterByCategory.length) {
				const CATEGORY_IDS = t.filterByCategory.map(category => category.entityId)
				tableData = tableData.filter(item => CATEGORY_IDS.includes(item.qualificationCategoryId))
			}

			// Sort by Qualification Name
			tableData = tableData.sort((a, b) => (a.qualificationName > b.qualificationName ? 1 : -1))
			return tableData
		},

	},

	methods: {

		/**
		 * Clear Filters
		 *
		 * Clear all the filters.
		 */
		clearFilters() {
			const t = this
			t.filterByCategory = []
		},

		/**
		 * Close Right Panel
		 *
		 * Close the right panel.
		 */
		closeRightPanel() {
			const t = this

			t.selectedItem = {}
			t.isRightPanelVisible = false
		},

		/**
		 *
		 * Delete Preset
		 *
		 * Delete the selected preset by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deletePreset(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('qualificationPreset', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting qualification', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted qualification'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 *
		 * Delete Qualification
		 *
		 * Delete the selected qualification by its ID.
		 *
		 * @param itemData {object} the item to delete
		 */
		async deleteQualification(itemData) {
			const t = this

			const RESPONSE = await t.MIX_redis_delete('qualification', itemData.entityId, itemData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error deleting qualification', RESPONSE.error)
				return
			}

			t.$sharedState.successMessage = 'Deleted qualification'

			// Reload the data
			await t.loadData()

			// Close the panel
			t.closeRightPanel()
		},

		/**
		 * Edit Item
		 *
		 * Toggle the editability of the form.
		 */
		editItem() {
			const t = this

			t.isReadOnly = !t.isReadOnly
		},

		/**
		 * Emitted Reload Page
		 *
		 * Close the right panel and reload the data to refresh the page.
		 */
		async emittedReloadData() {
			const t = this

			t.isReadOnly = true

			//Reload the data
			await t.loadData()

			// Close the right panel
			t.closeRightPanel()
		},

		/**
		 * Export Data
		 *
		 * Export the data to CSV.
		 */
		exportData() {
			const t = this

			t.MIX_exportDocuments(t.computedExportCSV.headers, 'Qualifications', t.computedExportCSV.data)
		},

		/**
		 * Get Category By ID
		 *
		 * Get the category name by its ID.
		 *
		 * @param qualificationCategoryId {string} the category ID
		 * @returns {string} the category name
		 */
		getCategoryById(qualificationCategoryId) {
			const t = this
			const CATEGORIES_DATA = t.qualificationCategoriesData

			// Find the category
			const CATEGORY = CATEGORIES_DATA.find(category => category.entityId === qualificationCategoryId)

			return CATEGORY?.qualificationCategoryName || 'None'
		},

		/**
		 * Handle Emitted Menu Selection
		 *
		 * Take the emitted selection from the menu, and call the relevant function.
		 *
		 * @param selection a string-based menu option
		 */
		handleEmittedMenuSelection(selection) {
			const t = this

			if (selection === 'Export') t.exportData()
		},

		/**
		 * Handle Qualification Selection
		 *
		 * Set the selected Qualification and open the Qualification form.
		 *
		 * @param selectedItem {object} the selected Qualification
		 */
		async handleQualificationSelection(selectedItem) {
			const t = this

			t.openRightPanel(selectedItem, 'qualificationAdmin')
		},

		/**
		 * Load Data
		 *
		 * Load all the data required for the page.
		 *
		 * @returns {Promise<void>}
		 */
		async loadData() {
			const t = this

			t.isLoading = true

			await Promise.all([
				t.loadQualificationPresetsData(),
				t.loadQualificationCategoriesData(),
				t.loadUsersData(),
			])

			t.isLoading = false
		},

		/**
		 * Load Qualification Categories Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadQualificationCategoriesData() {

			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('qualificationCategory')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Qualification data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
				return
			}

			// Assign the data - sort by name
			t.qualificationCategoriesData = RESPONSE.data?.sort((a, b) => (a.qualificationCategoryName > b.qualificationCategoryName ? 1 : -1))
		},

		/**
		 * Load Qualification Presets Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadQualificationPresetsData() {

			const t = this

			// Fetch the data
			const RESPONSE = await t.MIX_redis_getAll('qualificationPreset')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Qualfication data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
				return
			}

			// Assign the data
			t.qualificationPresetsData = RESPONSE.data
		},

		/**
		 * Load Users Data
		 *
		 * Load the required data from the database.
		 *
		 * @returns {Promise<void>}
		 */
		async loadUsersData() {
			const t = this

			const RESPONSE = await t.MIX_redis_getAll('user')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting Users data: ', RESPONSE.error)
				return
			}

			// Assign the data to the state
			t.usersData = RESPONSE.data
		},

		/**
		 * On Search Blur
		 *
		 * When the search field is blurred, set the isSearchFocused flag to false.
		 */
		onSearchBlur() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = false
		},

		/**
		 * On Search Focus
		 *
		 * When the search field is focused, set the isSearchFocused flag to true.
		 * This is used to expand the search when on smaller devices.
		 */
		onSearchFocus() {
			const t = this

			if (t.$vuetify.breakpoint.width < 600) t.isSearchFocused = true
		},

		/**
		 * Open Form
		 *
		 * Open the form dialog.
		 * If no item is passed in, the form will open as new (blank).
		 *
		 * @param item {object} the selected key
		 * @param formType {string} the type of form to open
		 */
		openRightPanel(item, formType) {
			const t = this

			// Show the selected item or if no item selected, open a blank form
			if (item?.entityId) {
				t.selectedItem = {...item}
				t.isReadOnly = true
			} else {
				t.selectedItem = {}
				t.isReadOnly = false
			}

			// Set the form type to know which form to open
			t.formType = formType

			//Hide the right panel
			t.isRightPanelVisible = true
		},

		/**
		 * Reload Data
		 *
		 * Call to reload the data.
		 */
		reloadData() {
			const t = this

			t.loadData()
		},

		/**
		 * Toggle Export Dialog Visibility
		 *
		 * Toggle the visibility of the Export dialog.
		 */
		toggleExportDialogVisibility() {
			const t = this

			t.isExportDialogVisible = !t.isExportDialogVisible
		},

		/**
		 * Toggle Filters Visibility
		 *
		 * Toggle the visibility of the filters panel.
		 */
		toggleFiltersVisibility() {
			const t = this

			t.isFiltersPanelVisible = !t.isFiltersPanelVisible
		},

	},

	async mounted() {
		const t = this

		await t.loadData()
	},
}

</script>
