<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<div v-else>

		<!--Information-->
		<app-text>
			Here, you can bulk create Users for your Organisation by uploading a CSV file of User's data, and an optional
			ZIP file of images.
			<br>
			<br>
			This will create an account for each User in the CSV file, assign them to your Organisation, and email them
			their login details.
		</app-text>

		<v-divider class="greyD my-4"/>

		<!--CSV ---------------------------------------------------------------------------------------------------- -->

		<!--Instructions-->
		<app-text>
			1) - Create a CSV file with the following columns:
			<ul>
				<li>
					<code>userName</code> - The User's full name
				</li>
				<li>
					<code>userPosition</code> - The User's position in the Organisation
				</li>
				<li>
					<code>userEmail</code> - The User's email address
				</li>
				<li>
					<code>userTelephone</code> - The User's telephone number
				</li>
			</ul>
		</app-text>

		<!--Files-->
		<div class="d-flex mt-4">

			<!--Hidden-->
			<input @change="handleCSV"
				   accept=".csv"
				   ref="csvInput"
				   style="display: none"
				   type="file">

			<!--Visible-->
			<app-btn @click.native="onCsvClick"
					 :color="csvFile ? 'green' : 'primary'"
					 :icon="csvFile ? '' : 'add'"
					 :label="csvFile ? 'CSV File Added' : 'Add a CSV File'"/>

		</div>

		<v-divider class="greyD my-4"/>

		<!--ZIP ---------------------------------------------------------------------------------------------------- -->

		<!--Instructions-->
		<app-text>
			2) - (optional) - Create a ZIP file of images, with each image's name being the User's email address (this must match the
			userEmail in the CSV file).
		</app-text>

		<!--Files-->
		<div class="d-flex mt-4">

			<!--Hidden-->
			<input @change="handleZIP"
				   accept=".zip"
				   ref="zipInput"
				   style="display: none"
				   type="file">

			<!--Visible-->
			<app-btn @click.native="onZipClick"
					 :color="zipFile ? 'green' : 'primary'"
					 :icon="zipFile ? '' : 'add'"
					 :label="zipFile ? 'ZIP File Added' : 'Add a ZIP File'"/>

		</div>

		<!--RAMS --------------------------------------------------------------------------------------------------- -->
		<form-section-title class="mt-8" title="RAMS"/>
		<div class="d-flex mt-4">
			<div @click="handleRamsCheckbox" class="appWhite d-flex align-center justify-center rounded-lg mr-4"
				 style="aspect-ratio: 1/1; height: 56px; width: 56px">
				<app-icon v-if="isRamsAccepted" color="green" icon="check" size="32"/>
			</div>
			<app-text>
				By adding this/these user(s), I confirm they have read, understood and accepted the provided Risk
				Assessments and Method Statement and will work to the specified safety guidelines and systems of work in
				accordance of their work.
			</app-text>
		</div>
		<app-text v-if="errors.isRamsAccepted" color="red" size="small">{{errors.isRamsAcceptedErrorMessage}}</app-text>

		<!--Upload ------------------------------------------------------------------------------------------------- -->

		<!--Upload Files-->
		<div class="d-flex justify-end mt-4">
			<app-btn @click.native="uploadFiles"
					 color="green"
					 :disabled="!csvFile"
					 label="Upload Files"/>
		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<v-dialog max-width="512" v-model="isResultsDialogVisible">

			<div class="appGrey rounded-lg pa-4">

				<!--Successful-->
				<div v-if="uploadResults?.successfulRecords?.length">

					<app-text size="normal-bold">
						{{ uploadResults.successfulRecords.length }} accounts were successfully created:
					</app-text>

					<div v-for="record in uploadResults.successfulRecords" :key="record.userEmail"
						 class="d-flex align-center mt-4">
						<app-icon class="mr-4" color="green" icon="check" size="24"/>
						<div>
							<app-text>{{ record.userName }}</app-text>
							<app-text color="grey9" size="small">{{ record.userEmail }}</app-text>
						</div>
					</div>

				</div>

				<!--Failed-->
				<div v-if="uploadResults?.failedRecords?.length">

					<app-text size="normal-bold">
						{{ uploadResults.failedRecords.length }} accounts were not created:
					</app-text>

					<div v-for="record in uploadResults.failedRecords" :key="record.userEmail"
						 class="d-flex align-center mt-4">
						<app-icon class="mr-4" color="red" icon="close" size="24"/>
						<div>
							<app-text>{{ record.userName }}</app-text>
							<app-text color="grey9" size="small">{{ record.userEmail }}</app-text>
							<app-text color="red" size="small">{{ record.error }}</app-text>
						</div>
					</div>

				</div>

				<v-divider class="mt-4"/>

				<!--Close Button-->
				<div class="mt-4">

					<app-btn @click.native="closeResultsDialog"
							 :block="true"
							 label="Close"/>

				</div>

			</div>

		</v-dialog>

	</div>

</template>

<script>

export default {

	name: "OrganisationBulkUserForm",

	props: ['formData'],

	data: () => ({
		csvFile: null,
		errors: {
			isRamsAccepted: false,
			isRamsAcceptedErrorMessage: '',
		},
		isLoading: false,
		isRamsAccepted: false,
		isResultsDialogVisible: false,
		uploadResults: {},
		zipFile: null,
	}),

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close Results Dialog
		 *
		 * Closes the results dialog and emits back to the parent to reload the page.
		 */
		closeResultsDialog() {
			const t = this

			t.isResultsDialogVisible = false

			t.$emit('emitReloadPage')
		},

		/**
		 * Handle CSV
		 *
		 * Handles the CSV file input.
		 * This is hidden, and is triggered by the onCsvClick method.
		 *
		 * @param event {Event} - The event
		 */
		handleCSV(event) {
			this.csvFile = event.target.files[0]
		},

		/**
		 * Handle Rams Checkbox
		 *
		 * When the Rams checkbox is clicked, toggle the isRamsAccepted property.
		 */
		handleRamsCheckbox() {
			const t = this

			// Don't allow changes if read only
			if (t.isReadOnly) return

			t.isRamsAccepted = !t.isRamsAccepted
		},

		/**
		 * Handle ZIP
		 *
		 * Handles the ZIP file input.
		 * This is hidden, and is triggered by the onZipClick method.
		 *
		 * @param event {Event} - The event
		 */
		handleZIP(event) {
			this.zipFile = event.target.files[0]
		},

		/**
		 * On CSV Click
		 *
		 * Handles the CSV file input click.
		 * This is visible and triggers the hidden CSV input.
		 */
		onCsvClick() {
			this.$refs.csvInput.click()
		},

		/**
		 * On ZIP Click
		 *
		 * Handles the ZIP file input click.
		 * This is visible and triggers the hidden ZIP input.
		 */
		onZipClick() {
			this.$refs.zipInput.click()
		},

		/**
		 * Open Results Dialog
		 *
		 * Opens the results dialog.
		 */
		openResultsDialog() {
			this.isResultsDialogVisible = true
		},

		/**
		 * Upload Files
		 *
		 * Uploads the CSV and ZIP files to the server.
		 */
		async uploadFiles() {
			const t = this
			let serverResponse

			// Only continue if the CSV file has been uploaded, ZIP is optional
			if (!t.csvFile) return

			// Validate the form
			if (!t.validateForm()) return

			t.isLoading = true

			// Construct the user config data
			const USER_CONFIG_DATA = {
				userOrganisation: t.$props.formData.organisationData.entityId,
				userType: 'Contractor',
				userRole: 'User',
				userLevel: 'Contractor-User'
			}

			// Construct a FormData object
			const formData = new FormData()
			formData.append('csv', this.csvFile)
			if (t.zipFile) formData.append('zip', this.zipFile) // Optional
			formData.append('userConfigData', JSON.stringify(USER_CONFIG_DATA))


			if (t.zipFile) serverResponse = await t.MIX_redis_bulkCreateUsersWithImages(formData)
			else serverResponse = await t.MIX_redis_bulkCreateUsers(formData)

			// Handle any errors
			if (serverResponse.hasErrors) {
				console.error('Error bulk creating users: ', serverResponse.errors)
				t.$sharedState.errorMessage = 'There was a problem creating these users, please try again.'
				return
			}

			t.isLoading = false

			t.uploadResults = serverResponse.data
			t.openResultsDialog()
		},

		/**
		 * Validate Form
		 *
		 * Validate the form, and return true if it's valid.
		 * If it's not valid, return false and set the errors.
		 */
		validateForm() {
			const t = this

			// Clear any errors
			t.clearErrors()

			// RAMS
			if (!t.isRamsAccepted) {
				t.errors.isRamsAccepted = true
				t.errors.isRamsAcceptedErrorMessage = 'Please confirm that the user has read and accepted the RAMS'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
