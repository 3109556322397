<template>
	<div class="appWhite d-flex align-center rounded-lg pa-4">

		<avatar class="mr-4" :file="cardData.fileData" size="48" :user="cardData.userData"/>

		<!--Name | Position-->
		<div style="width: 100%">

			<!--Name-->
			<app-text>{{ cardData.userData.userName }}</app-text>

			<v-divider class="my-1"/>

			<!--Position-->
			<app-text color="grey9" size="small">{{ cardData.userData.userPosition }}</app-text>

			<!--Is A Manager - Organisation Managers only-->
			<app-text v-if="cardData.userData.userLevel === 'Contractor-Manager'"
					  color="primary" size="small">Manager
			</app-text>

		</div>

	</div>
</template>

<script>

export default {

	name: "MyOrganisationMobileCard",

	props: ['cardData'],

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
