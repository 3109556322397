<template>
	<div class="appGrey pa-4">

		{{ computedInit }}

		<!--Stats Bar-->
		<div class="appWhite d-flex justify-center rounded-lg pa-2">

			<!--Time Taken-->
			<div class="d-flex flex-column align-center">
				<app-text size="small">Time Taken</app-text>
				<app-text size="normal-bold">{{ computedFormatTimeTaken }}</app-text>
			</div>

			<v-divider class="mx-8" vertical/>

			<!--Number In-->
			<div class="d-flex flex-column align-center">
				<app-text size="small">In</app-text>
				<app-text size="normal-bold">{{ computedNumberIn }}</app-text>
			</div>

			<v-divider class="mx-8" vertical/>

			<!--Number Out-->
			<div class="d-flex flex-column align-center">
				<app-text size="small">Out</app-text>
				<app-text size="normal-bold">{{ computedNumberOut }}</app-text>
			</div>

		</div>

		<!--Form-->
		<v-row class="mt-4" no-gutters>

			<!--Title-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								label="Title"
								v-model.trim="form.frcTitle"/>
			</v-col>

			<!--Type-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Type"
								v-model.trim="form.frcType"/>
			</v-col>

			<!--Site-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
				<app-form-field form-type="textInput"
								:disabled="true"
								label="Site"
								v-model.trim="siteData.siteName"/>
			</v-col>

			<!--Notes-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '12'">
				<app-form-field form-type="textArea"
								:disabled="isReadOnly"
								label="Notes"
								v-model.trim="form.frcNotes"/>
			</v-col>

		</v-row>

		<!--Users-->
		<form-section-title class="mt-8" title="Users"/>
		<app-text v-if="!form.frcUsers?.length" color="grey9">No users were recorded for this Fire Roll Call</app-text>
		<div class="user-cards-grid mt-4">

			<div v-for="item in form.frcUsers" class="appWhite rounded-lg pa-4">

				<app-text size="normal-bold">{{ getUserData(item.id)?.userName }}</app-text>
				<app-text color="grey9" size="small">{{ getUserData(item.id)?.userPosition }}</app-text>

				<!--SWAPPed In User-->
				<div v-if="item.swappStatus === 'In'" class="d-flex align-center">
					<app-icon color="green" icon="qrCode" size="32"></app-icon>
					<app-text size="small">Was SWAPPed In</app-text>
				</div>

				<!--SWAPPed Out User-->
				<div v-if="item.swappStatus === 'Out'" class="d-flex align-center">
					<app-icon color="red" icon="qrCode" size="32"></app-icon>
					<app-text size="small">{{ getSwappedOutBeforeTime(item.swappTime) }}</app-text>
				</div>

			</div>

		</div>

	</div>
</template>

<script>

export default {

	name: "FireRollCallForm",

	props: ['formData', 'isReadOnly', 'siteData', 'usersData'],

	data: () => ({
		form: {
			frcTitle: '',
			frcSiteId: '',
			frcType: '',
			frcTimeTaken: 0,
			frcNotes: '',
			frcUsers: [],

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: ''
		},
	}),

	computed: {

		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			t.form = FORM_DATA
			t.form.frcUsers = FORM_DATA.frcUsers.map(user => {
				if (typeof user === 'string') return JSON.parse(user)
				else return user
			})
		},

		/**
		 * Computed Format Time Taken
		 *
		 * Return the time taken in HH:MM:SS format.
		 *
		 * @returns {string} - HH:MM:SS
		 */
		computedFormatTimeTaken() {
			const t = this
			const TIME_TAKEN = t.form.frcTimeTaken

			const SECONDS = TIME_TAKEN % 60
			const MINUTES = Math.floor(TIME_TAKEN / 60) % 60
			const HOURS = Math.floor(TIME_TAKEN / 3600)

			return `${String(HOURS).padStart(2, '0')}:${String(MINUTES).padStart(2, '0')}:${String(SECONDS).padStart(2, '0')}`
		},

		/**
		 * Computed Number In
		 *
		 * Return the number of users who were swapped in.
		 *
		 * @returns {number}
		 */
		computedNumberIn() {
			const t = this
			const FRC_USERS = t.form.frcUsers

			return FRC_USERS.filter(user => user.swappStatus === 'In').length
		},

		/**
		 * Computed Number Out
		 *
		 * Return the number of users who were swapped out.
		 *
		 * @returns {number}
		 */
		computedNumberOut() {
			const t = this
			const FRC_USERS = t.form.frcUsers

			return FRC_USERS.filter(user => user.swappStatus === 'Out').length
		},

	},

	methods: {

		/**
		 * Get Swapped Out Before Time
		 *
		 * Return a message to signify how long a user was swapped out before the Fire Roll Call.
		 *
		 * @param swappTime - The time the user was swapped out
		 */
		getSwappedOutBeforeTime(swappTime) {
			const t = this
			const FORM_CREATED_DATE_TIME = t.form.createdDateTime

			const SWAPPED_OUT_BEFORE_TIME = FORM_CREATED_DATE_TIME - swappTime

			const SECONDS = SWAPPED_OUT_BEFORE_TIME % 60
			const MINUTES = Math.floor(SWAPPED_OUT_BEFORE_TIME / 60) % 60

			return `SWAPPed Out ${MINUTES}m ${SECONDS}s before Fire Roll Call`
		},

		/**
		 * Get User Data
		 *
		 * Return the user data based on the user ID.
		 *
		 * @param userId - The user ID
		 * @returns {object} - The user data
		 */
		getUserData(userId) {
			const t = this
			const USERS_DATA = t.$props.usersData

			return USERS_DATA.find(user => user.entityId === userId)
		},

	},

}
</script>

<style scoped>
.user-cards-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 16px;
}
</style>
