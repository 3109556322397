<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--If the user has not completed their induction-->
	<div v-else-if="!userInductionData.length">

		<!--Header-->
		<page-title :divider="true"
					icon="orientation"
					info="Please check to confirm you have read and understood the following before SWAPPing into a site."
					title="Orientation"/>

		<!--If Orientation data is found, show the completed graphic-->
		<div class="d-flex flex-column text-center mt-8">

			<app-text size="medium-bold">Missing Induction</app-text>

			<app-icon class="pulse" color="red" icon="cancel" size="256"/>
			<app-text>You must completed your Induction before your Orientation.</app-text>
			<app-text class="mt-4">Please speak to the Site Manager for further assistance.</app-text>


		</div>

	</div>

	<!--Page Content-->
	<div v-else>

		<!--Header-->
		<page-title :divider="true"
					icon="orientation"
					info="Please check to confirm you have read and understood the following before SWAPPing into a site."
					title="Orientation"/>

		<v-tabs class="rounded-lg mt-4" v-model="tabs">

			<!--My Orientations-->
			<v-tab href="#myOrientations">
				<app-text size="small">My Orientations</app-text>
			</v-tab>

			<!--Site QR Codes-->
			<v-tab v-if="MIX_getCurrentUser().userAccessAndAbilities.includes('Orientation: View QR Codes')"
				   href="#siteQrCodes">
				<app-text size="small">Site QR Codes</app-text>
			</v-tab>

		</v-tabs>

		<v-tabs-items v-model="tabs">

			<!--My Orientations-->
			<v-tab-item class="appGrey" value="myOrientations">

				<!--Information-->
				<app-text class="mt-4">
					You must complete Orientation before entering a Site.
					<br>
					<br>
					Speak to a Site Manager to scan a site QR code, or SWAPP into a site to start Orientation.
					<br>
					<br>
					These are the site you have completed, or are starting Orientation for:
				</app-text>

				<!--Site Selection-->
				<app-form-field form-type="autoComplete"
								class="mt-4"
								:items="computedSitesData"
								item-text="siteName"
								label="Sites"
								:return-object="true"
								v-model="selectedSite"/>

				<v-divider class="mt-4"/>

				<!--If not Orientation data is found, show the form-->
				<div v-if="selectedSite?.entityId && !computedGetOrientationDataForCurrentSite?.entityId">

					<div class="mt-4" v-for="question in questions" :key="question.id">

						<!--Checkbox | Question-->
						<div class="d-flex align-center">

							<app-text class="mr-4">{{ question.id }}</app-text>

							<!--Checkbox-->
							<div @click="toggleCheckbox(question)"
								 :class="['appWhite d-flex align-center justify-center rounded-lg mr-4', {'checked': question.checked}]"
								 style="aspect-ratio: 1/1; height: 56px; min-width: 56px; border: 1px solid lightgrey">
								<app-icon v-if="question.checked" color="green" icon="check" size="32"/>
							</div>

							<!--Question-->
							<app-text>{{ question.text }}</app-text>

						</div>

						<v-divider class="greyD my-4"/>

					</div>

					<!--Checkbox | Question-->
					<div class="d-flex align-center">

						<!--Checkbox-->
						<div @click="toggleConfirmationCheckbox()"
							 :class="['appWhite d-flex align-center justify-center rounded-lg mr-4', {'checked': confirmationCheckbox}]"
							 style="aspect-ratio: 1/1; height: 56px; min-width: 56px; border: 1px solid lightgrey">
							<app-icon v-if="confirmationCheckbox" color="green" icon="check" size="32"/>
						</div>

						<!--Question-->
						<app-text>
							By signing this induction form I confirm that all of the items above have been fully
							explained to me
							and
							if unsure of any I questioned with the inductor to which I had a satisfactory answer.
							<br>
							I, have read, understood and accepted the provided Risk Assessments and Method Statement and
							will
							work to the specified safety guidelines and systems of work in accordance of my work.
						</app-text>

					</div>

					<v-divider class="greyD my-4"/>

					<!--Save Button-->
					<div class="d-flex flex-column align-end mt-4">

						<app-text v-if="!isReadyToSave" color="red">You must accept all items above before saving
						</app-text>

						<app-btn @click.native="handleSave"
								 :block="$vuetify.breakpoint.width < 600"
								 class="mt-4"
								 color="green"
								 label="Save"
								 :style="$vuetify.breakpoint.width < 600 && 'width: 100%'"/>

					</div>

				</div>

				<!--If Orientation data is found, show the completed graphic-->
				<div v-if="selectedSite?.entityId && computedGetOrientationDataForCurrentSite?.entityId"
					 class="d-flex flex-column text-center mt-8">

					<app-text>Present this to show you have completed your Orientation for this site.</app-text>

					<app-text class="mt-8" size="medium-bold">{{ selectedSite.siteName }}</app-text>
					<app-icon class="pulse" color="green" icon="success" size="256"/>
					<app-text size="medium-bold">Orientation complete</app-text>
					<app-text size="small">
						<span>{{ MIX_formatDate(computedGetOrientationDataForCurrentSite.createdDateTime, 'long')
							}}</span>
						<span class="mx-2">@</span>
						<span>{{ MIX_formatDateTimeToTime(computedGetOrientationDataForCurrentSite.createdDateTime)
							}}</span>
					</app-text>

				</div>

			</v-tab-item>

			<!--Site QR Codes-->
			<v-tab-item class="appGrey" value="siteQrCodes">

				<app-text class="mt-4">
					Select a site to show its QR Code.
					<br>
					<br>
					Users can scan this to start their Orientation for the selected Site.
				</app-text>

				<!--Site Selection-->
				<app-form-field form-type="autoComplete"
								class="mt-4"
								:items="sitesData"
								item-text="siteName"
								label="Sites"
								:return-object="true"
								v-model="selectedSite"/>

				<!--QR Code-->
				<div v-if="selectedSite?.entityId" class="d-flex justify-center mt-8">
					<qrcode-vue background="#ffffff"
								foreground="black"
								level="H"
								size="343"
								:value="computedQrCodeUrl"/>
				</div>

			</v-tab-item>

		</v-tabs-items>


	</div>

</template>

<script>

import QrcodeVue from "qrcode.vue";

export default {

	name: "Orientation",

	components: {QrcodeVue},

	data: () => ({
		form: {
			entityId: '',
			orientationUserId: '',
			orientationSiteId: '',

			createdDateTime: 0,
			createdUserId: '',
			createdUserName: '',
			modifiedDateTime: 0,
			modifiedUserId: '',
			modifiedUserName: '',
			isDeleted: false,
			deletedDateTime: 0,
			deletedUserId: '',
			deletedUserName: '',
		},

		confirmationCheckbox: false,
		currentEnvironment: '',
		isLoading: false,
		isReadyToSave: true,
		questions: [
			{
				id: 1,
				text: 'I confirm that I have watched the induction video in the past 12 months and have been issued with the current induction sticker and have a Commitment Card which is completed.',
				checked: false
			},
			{
				id: 2,
				text: 'My employer has explained the method statement/risk assessment for the work I will be undertaking, and I agree to comply with the content.',
				checked: false
			},
			{
				id: 3,
				text: 'I confirm that I speak/understand English language and standard warning signs.',
				checked: false
			},
			{id: 4, text: 'I understand the Site management structure and my supervision.', checked: false},
			{
				id: 5,
				text: 'I have been explained the emergency alarm procedure/muster point and action in event of a fire.',
				checked: false
			},
			{
				id: 6,
				text: 'I understand that smoking / vaping is banned within buildings, offices and welfare facilities.',
				checked: false
			},
			{id: 7, text: 'I am aware of the first aid arrangements on site.', checked: false},
			{id: 8, text: 'I know the entry procedures and pedestrian and vehicle routes to site.', checked: false},
			{id: 9, text: 'I have been made aware of the car parking arrangements.', checked: false},
			{
				id: 10,
				text: 'I am aware that there are access restrictions and any areas which are out of bounds?',
				checked: false
			},
			{
				id: 11,
				text: 'I understand that nobody is allowed on site under the influence of alcohol or drugs unless prescribed. I consent to random screening whilst on site.',
				checked: false
			},
			{id: 12, text: 'The Safety Observation reporting procedure has been explained to me.', checked: false},
			{
				id: 13,
				text: 'I acknowledge that appropriate welfare is available on site. There is no eating allowed in any other area. Everyone to follow “Clean as you go” policy.',
				checked: false
			},
			{
				id: 14,
				text: 'All the site rules have been explained to me and I know there are copies in the welfare facilities.',
				checked: false
			},
			{
				id: 15,
				text: 'I acknowledge all accidents/Incidents are to be reported including Safety Observations.',
				checked: false
			},
			{
				id: 16,
				text: 'I understand that I must wear a safety helmet, safety boots and hi-viz at all times, all other PPE such as hearing and eye protection and RPE is task specific.',
				checked: false
			},
			{
				id: 17,
				text: 'I understand the working hours of this development and also the noise nuisance and restrictions.',
				checked: false
			},
			{
				id: 18,
				text: 'No mobile telephones to be used when operating plant or machinery or where their use may cause distraction from the task in hand.',
				checked: false
			},
			{
				id: 19,
				text: 'I will supply all test certificates for lifting appliances, accessories and harnesses.',
				checked: false
			},
			{
				id: 20,
				text: 'Site vehicles, plant and machines are only to be operated by persons with proof of training and competence certificate (CPCS, IPAF etc). All plant keys to be removed and switched off when not in use.',
				checked: false
			},
			{
				id: 21,
				text: 'I acknowledge the site speed limit traffic / pedestrian routes have been explained to me.',
				checked: false
			},
			{
				id: 22,
				text: 'All fire prevention, fire marshal and firefighting equipment has been pointed out to me.',
				checked: false
			},
			{id: 23, text: 'All permit procedures have been explained to me.', checked: false},
			{
				id: 24,
				text: 'I acknowledge that personal hand tools should be inspected before first use daily, and any defects corrected.',
				checked: false
			},
			{
				id: 25,
				text: 'I am aware of the environmental conditions and special arrangements relating to this development.',
				checked: false
			},
			{
				id: 26,
				text: 'Scaffold is not to be erected, dismantled or altered except by authorised persons. Proof of training and competency is required which includes tower scaffolding.',
				checked: false
			},
			{
				id: 27,
				text: 'All material Storage, waste disposal and housekeeping arrangements and procedures have been explained to me.',
				checked: false
			},
			{id: 28, text: 'I acknowledge that materials to be lowered not thrown.', checked: false},
			{
				id: 29,
				text: 'I understand that safety monitoring arrangements safety inspections and site instruction are in place to protect me and my colleagues.',
				checked: false
			},
			{
				id: 30,
				text: 'You are encouraged to discuss any constructive aspects of safety with management.',
				checked: false
			},
		],
		selectedSite: {},
		tabs: '',

		// Data
		orientationsData: [],
		sitesData: [],
		userInductionData: [],
	}),

	computed: {

		/**
		 * Computed Get Orientation Data For Current Site
		 *
		 * Get the orientation data for the current site.
		 *
		 * @returns {object} - The orientation data for the current site
		 */
		computedGetOrientationDataForCurrentSite() {
			const t = this
			const ORIENTATION_DATA = t.orientationsData
			const SELECTED_SITE = t.selectedSite

			return ORIENTATION_DATA.find(orientation => orientation.orientationSiteId === SELECTED_SITE.entityId)
		},

		/**
		 * Computed Sites Data
		 *
		 * Return following sites:
		 *  - That the user has passed orientation for
		 *  - For any site that's held in local storage
		 *  - A selected site from the URL query parameters
		 * Sort the sites data by siteName.
		 *
		 * @returns {array} - The sorted sites data
		 */
		computedSitesData() {
			const t = this
			const ORIENTATIONS_DATA = t.orientationsData
			const SITES_DATA = t.sitesData
			const STORED_SITE_ID = t.MIX_getFromLocalStorage('orientationSiteId')
			const URL_PARAMS = new URLSearchParams(window.location.search)
			const SITE_ID = URL_PARAMS.get('siteId')

			// Get the sites that the user has passed orientation for
			const PASSED_SITES = ORIENTATIONS_DATA.map(orientation => SITES_DATA.find(site => site.entityId === orientation.orientationSiteId))

			// Get the sites that are held in local storage
			const STORED_SITE = SITES_DATA.find(site => site.entityId === STORED_SITE_ID)

			// Get the selected site from the URL query parameters
			const SELECTED_SITE = SITES_DATA.find(site => site.entityId === SITE_ID)

			// Combine the sites
			const ALL_SITES = [...PASSED_SITES, STORED_SITE, SELECTED_SITE]

			// Sort the sites by siteName
			return ALL_SITES.sort((a, b) => a.siteName > b.siteName ? 1 : -1)
		},

		computedQrCodeUrl() {
			const t = this
			const SELECTED_SITE = t.selectedSite

			return `${process.env.VUE_APP_SITE_URL}/orientation?siteId=${SELECTED_SITE?.entityId}`
		},

	},

	methods: {

		/**
		 * Create Item
		 *
		 * Create a new Orientation item in the database.
		 */
		async createItem() {
			const t = this

			// Set the form data
			t.form.orientationUserId = t.MIX_getCurrentUser().entityId
			t.form.orientationSiteId = t.selectedSite.entityId

			const RESPONSE = await t.MIX_redis_create('orientation', t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving Orientation: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong when trying to save Orientation, Please try again.'
				return
			}

			t.$sharedState.successMessage = 'Saved Orientation'

			// Reset the checkboxes
			t.questions.forEach(question => question.checked = false)
			t.confirmationCheckbox = false

			// Reload the data
			await t.loadOrientationsData()
		},

		/**
		 * Handle Save
		 *
		 * Handle the save button click.
		 * Validate the form and call to save the data.
		 */
		async handleSave() {
			const t = this

			// Only continue if the form is valid
			if (!t.validateForm()) return

			await t.createItem()
		},

		/**
		 * Load Data
		 *
		 * Load the data for the page.
		 */
		async loadData() {
			const t = this

			// Set the loading flag
			t.isLoading = true

			await Promise.all([
				t.loadSitesData(),
				t.loadOrientationsData(),
				t.loadUserInductionData(),
			])

			// Set the loading flag
			t.isLoading = false
		},

		/**
		 * Load Orientations Data
		 *
		 * Load the orientations data from the database.
		 */
		async loadOrientationsData() {
			const t = this

			// Get all orientations data for the current user
			const RESPONSE = await t.MIX_redis_getOrientationsWhere([
				{
					whereKey: 'orientationUserId',
					whereValue: t.MIX_getCurrentUser().entityId,
				}
			])

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting orientations data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong when trying to get orientations data, Please try again.'
				return
			}

			// Set the orientations data
			t.orientationsData = RESPONSE.data
		},

		/**
		 * Load Sites Data
		 *
		 * Load the sites data from the database.
		 */
		async loadSitesData() {
			const t = this

			// Get the sites data
			const RESPONSE = await t.MIX_redis_getAll('site')

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting sites data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong when trying to get sites data, Please try again.'
				return
			}

			// Set the sites data
			t.sitesData = RESPONSE.data.sort((a, b) => a.siteName > b.siteName ? 1 : -1)
		},

		/**
		 * Load User Induction Data
		 *
		 * Load the user induction data from the database.
		 */
		async loadUserInductionData() {
			const t = this

			// Get the user induction data
			const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
				'Induction',
				[{whereKey: 'inductionUserId', whereValue: t.MIX_getCurrentUser().entityId}],
				[]
			)
			console.log('RESPONSE: ', RESPONSE)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error getting user induction data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong when trying to get user induction data, Please try again.'
				return
			}

			t.userInductionData = RESPONSE.data
		},

		/**
		 * Toggle Checkbox
		 *
		 * Toggle the checked state of a question.
		 *
		 * @param question {object} - The question object
		 */
		toggleCheckbox(question) {
			question.checked = !question.checked
		},

		/**
		 * Toggle Confirmation Checkbox
		 *
		 * Toggle the checked state of the confirmation checkbox.
		 */
		toggleConfirmationCheckbox() {
			this.confirmationCheckbox = !this.confirmationCheckbox
		},

		/**
		 * Validate Form
		 *
		 * Validate the form to ensure all questions are checked and the confirmation checkbox is checked.
		 *
		 * @returns {boolean} - Whether the form is valid or not
		 */
		validateForm() {
			const t = this

			// Reset the isReadyToSave flag
			t.isReadyToSave = true

			// Check if all questions are checked
			const IS_ALL_CHECKED = t.questions.every(question => question.checked)
			const IS_ACCEPTANCE_CHECKED = t.confirmationCheckbox

			// If any question or the acceptance checkbox are not checked, set the isReadyToSave flag to show the error
			if (!IS_ALL_CHECKED || !IS_ACCEPTANCE_CHECKED) t.isReadyToSave = false

			return t.isReadyToSave
		},

	},

	async mounted() {
		const t = this

		await t.loadData()

		// Set the current environment - this is used to determine which QR code link to show
		t.currentEnvironment = process.env.NODE_ENV

		// Check for URL query parameters (siteId)
		const URL_PARAMS = new URLSearchParams(window.location.search)
		const SITE_ID = URL_PARAMS.get('siteId')
		if (SITE_ID) t.selectedSite = t.sitesData.find(site => site.entityId === SITE_ID)

		// Check Local Storage for siteId
		const STORED_SITE_ID = t.MIX_getFromLocalStorage('orientationSiteId')
		if (STORED_SITE_ID) t.selectedSite = t.sitesData.find(site => site.entityId === STORED_SITE_ID)
	},

	beforeDestroy() {
		const t = this

		// Remove the siteId from Local Storage
		t.MIX_deleteFromLocalStorage('orientationSiteId')
	}

}
</script>

<style scoped>

</style>
