<template>
	<div class="appGrey">

		<v-toolbar color="primary">
			<app-text color="white" size="medium">Start a Fire Roll Call</app-text>

			<v-spacer/>

			<app-icon @click.native="closeDialog"
					  class="cursorPointer" color="white" icon="close"/>
		</v-toolbar>

		<div class="pa-4">

			<app-text size="normal-bold">You are about to start a Fire Roll Call</app-text>

			<app-text class="mt-8">The register shows:</app-text>
			<ul>

				<li>
					<app-text class="mt-4">
						The register shows users who are currently SWAPPed in
					</app-text>
				</li>

				<li>
					<app-text class="mt-4">
						Users who have SWAPPed out in the last hour will also be shown in case they've been delayed on
						their way out
					</app-text>
				</li>
			</ul>

			<app-text class="mt-8">Instructions for use:</app-text>
			<ul>

				<li>
					<app-text class="mt-4">Check off each person as you go</app-text>
				</li>

				<li>
					<app-text class="mt-4">Tap a person's image to call them</app-text>
				</li>

				<li>
					<app-text class="mt-4">Complete the form when you're finished</app-text>
				</li>

				<li>
					<app-text class="mt-4">The timer will stop when you submit the form</app-text>
				</li>

				<li>
					<app-text class="mt-4">You can cancel at any time</app-text>
				</li>

			</ul>

			<!--Site Selection-->
			<app-form-field form-type="select"
							class="mt-8"
							:error="errors.selectedSite"
							:error-message="errors.selectedSiteErrorMessage"
							:items="sitesData"
							item-text="siteName"
							label="Select a Site"
							:return-object="true"
							v-model="selectedSite"/>

			<!--Continue Button-->
			<app-btn @click.native="handleStartButton"
					 :block="true"
					 class="mt-4"
					 color="green"
					 label="Start"/>

		</div>

	</div>
</template>

<script>

export default {

	name: "FireRollCallStartDialog",

	props: ['sitesData'],

	data: () => ({
		errors: {
			selectedSite: false,
			selectedSiteErrorMessage: '',
		},
		selectedSite: {},
	}),

	methods: {

		/**
		 * Close Dialog
		 *
		 * Emits an event to close the dialog
		 */
		closeDialog() {
			this.$emit('closeDialog')
		},

		/**
		 * Handle Start Button
		 *
		 * Validates the form and emits an event to start the Fire Roll Call
		 */
		handleStartButton() {
			const t = this

			// Validate form
			if (!t.validateForm()) return

			t.$emit('startFireRollCall', t.selectedSite)
		},

		/**
		 * Validate Form
		 *
		 * Validates the form
		 *
		 * @returns {boolean}
		 */
		validateForm() {
			const t = this

			// Reset errors
			t.errors = {
				selectedSite: false,
				selectedSiteErrorMessage: '',
			}

			// Selected Site
			if (!t.selectedSite?.entityId) {
				t.errors.selectedSite = true
				t.errors.selectedSiteErrorMessage = 'Please select a site'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	mounted() {
		const t = this
		t.selectedSite = {}
	}

}
</script>

<style scoped>

</style>
